import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useDependentState from './use-dependent-state';

export type NavigationBlockPromptCallback = (tx: Transition) => void;

export const useNavigationBlockPrompt = (
  // this callback is invoked when the block status is true
  // and user tries to navigate to the other pages
  // we can pass either global or local state to control prompt state
  cb?: NavigationBlockPromptCallback,
  defaultIsBlocked = false,
) => {
  const [isBlocked, setIsBlocked] = useDependentState(defaultIsBlocked);

  const navigation = useContext(NavigationContext);
  const navigator = navigation.navigator as History;

  const transition = useRef<Transition | null>(null);
  const unblock = useRef<VoidFunction | null>(null);

  const handleBlock = useCallback(() => {
    unblock.current = navigator.block((tx) => {
      transition.current = tx;
      cb?.(tx);
    });
  }, [cb]);

  const handleUnblock = useCallback(() => {
    unblock.current?.();
    unblock.current = null;
  }, []);

  const retry = useCallback(
    (onNoTransition?: () => any) => {
      handleUnblock();
      if (!transition.current) return onNoTransition?.();
      transition.current.retry();
      transition.current = null;
    },
    [handleUnblock],
  );

  // block/unblock according to defaultIsBlocked value
  useEffect(() => {
    if (!isBlocked) return;

    handleBlock();
    return handleUnblock;
  }, [isBlocked, handleUnblock, handleBlock]);

  // The difference between setIsBlocked and handleSetIsBlocked lies in their action timing:
  // Both functions perform the same task of setting the block state, but their timing differs.
  // setIsBlocked updates the state and commits the change after the component re-renders, followed by cleanup.
  // handleSetIsBlocked commits the change immediately when the function is called, without waiting for re-render.
  // handleSetIsBlocked is more reliable in case you want to perform navigate right after you unblock the navigation
  const handleSetIsBlocked = useCallback(
    (isBlocked: boolean) => {
      // to immediately block/unblock navigation
      isBlocked ? handleBlock() : handleUnblock();
      setIsBlocked(isBlocked);
    },
    [setIsBlocked, handleBlock, handleUnblock],
  );

  return { isBlocked, setIsBlocked: handleSetIsBlocked, retry };
};
