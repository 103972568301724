import {
  EpInterviewSessionSizeType,
  EpInterviewSessionType,
  EpJobApplicationEpStatusEnum,
  EpJobVacancyState,
} from '~/types/graphql/graphql';
import { APPLICANT_TAB_PRIMARY } from '~/utils/constants';
import {
  InterviewInvitationDialogActionKey,
  InterviewInvitationDialogInvitationTypeKey,
  InterviewInvitationDialogSizeKey,
  InterviewInvitationFormRecruiterMessageStatusKey,
  InterviewInvitationFormSessionSelectTypeKey,
} from '~/utils/constants/interview-invitation';
import {
  MIXPANEL_ACCOUNT_FAQ_LINK_POSITION,
  MIXPANEL_ACCOUNT_HELP_LINK_POSITION,
  MIXPANEL_SCREENING_ANSWER_STATUS,
} from '~/utils/constants/mixpanel';
import {
  VACANCY_FORM_TYPE,
  VACANCY_TABS_INDONESIA,
} from '~/utils/constants/vacancy';

/**
 * Mixpanel type helper for reuse purposes
 */
export type MixpanelProfileDetailTabKeys =
  | 'profile'
  | 'CV'
  | 'notes'
  | 'rejection_detail';

export type MixpanelApplicantAcceptRejectActionLocationKeys = 'detail' | 'list';

const sourceDialogAcceptRejectApplicant = new Set([
  'Manage Interview',
  'Applicant',
] as const);

type TypeFromSet<T extends Set<any>> = T extends Set<infer U> ? U : never;

type SourceDialogAcceptRejectApplicant = TypeFromSet<
  typeof sourceDialogAcceptRejectApplicant
>;

/**
 * Mixpanel main event properties
 */
export type MixpanelUserProperties = {
  id: string;
  email: string;
  name: string;
  phoneNumber?: string;
  companyCode?: string;
  companyName?: string;
  companySize?: string;
  companyIndustry?: string;
  companyType?: string;
  userRole?: string;
  companyPIC?: string;
  companyStatus?: string;
  companyPackage?: string;
  isPremium?: string;
};

export type MixpanelUpdateUserProperties = {
  companyPackage?: string;
  isPremium?: string;
};

export type MixpanelOnSignInUserProperties = {
  email: string;
};

export type MixpanelOnboardingSelfRegistrationUserProperties = {
  email: string;
  name: string;
  phoneNumber: string;
};

export type MixpanelOnboardingSelfRegistrationSubmitProperties = {
  prospectName: string;
  prospectEmail: string;
  prospectPhone: string;
};

export type MixpanelApplicantSearchProperties = {
  keyword: string;
};

export type MixpanelApplicantFilterProperties = {
  educationLevelFilter: string[];
  universityName: string[];
  genderFilter: string;
  ageMinimumFilter: number;
  ageMaximumFilter: number;
  locationFilter: string[];
  yearOfExperienceFilter: string;
  salaryMinimumFilter: number;
  salaryMaximumFilter: number;
  experienceIndustryFilter: string[];
  skillFilter: string[];
  restrictionCity: boolean;
  restrictionEducationLevel: boolean;
  vacancyCode: string;
};

export type MixpanelApplicantProceedBulkProperties = {
  currentApplicantStatus: EpJobApplicationEpStatusEnum;
  newApplicantStatus: EpJobApplicationEpStatusEnum;
  tab: EpJobApplicationEpStatusEnum;
  mainTab?: APPLICANT_TAB_PRIMARY | '';
  secondaryTab?: EpJobApplicationEpStatusEnum | '';
  totalInvitedApplicant: number;
  totalNotInvitedApplicant: number;
  totalSelectedApplicant: number;
  vacancyCode?: string;
  vacancyCreatedAt?: string; // date iso string
  vacancyCloseDate?: string; // date iso string
  isRecommendation: boolean;
};

export type MixpanelApplicantProceedSingleProperties =
  MixpanelApplicantScreeningQuestionProperties & {
    currentApplicantStatus: EpJobApplicationEpStatusEnum;
    newApplicantStatus: EpJobApplicationEpStatusEnum;
    appliedDate: string; // date iso string
    vacancyCode?: string;
    vacancyCreatedAt?: string; // date iso string
    vacancyCloseDate?: string; // date iso string
    isRecommendation: boolean;
    mainTab?: APPLICANT_TAB_PRIMARY | '';
    secondaryTab?: EpJobApplicationEpStatusEnum | '';
    section:
      | 'detail'
      | 'list'
      | 'preview contact'
      | 'detail contact'
      | 'download CV'
      | 'download profile';
    isOnQuota: boolean | null;
    isInvited: boolean;
  };

export type MixpanelApplicantContactProperties = {
  contactType: 'whatsapp' | 'email';
  currentApplicantStatus: EpJobApplicationEpStatusEnum;
  appliedDate: string; // date iso string
};

export type MixpanelApplicantTabProperties = {
  mainTab: APPLICANT_TAB_PRIMARY;
  secondaryTab: EpJobApplicationEpStatusEnum;
};

export type MixpanelApplicantPreviewProperties =
  MixpanelApplicantScreeningQuestionProperties & {
    applicantID: string;
    appliedDate?: string; // date iso string
    isRecommendation: boolean;
    vacancyCode: string;
    currentApplicantStatus?: EpJobApplicationEpStatusEnum;
    isOnQuota: boolean | null;
    candidateID?: string;
    candidateName?: string;
    invitationStatus?: string;
    appliedStatus?: boolean;
    section?: string;
    isInvited?: boolean;
    mainTab: APPLICANT_TAB_PRIMARY | '';
    secondaryTab: EpJobApplicationEpStatusEnum | '';
  };

export type MixpanelApplicantDetailProperties = {
  applicantID: string;
  appliedDate: string; // date iso string
  isRecommendation: boolean;
  vacancyCode: string;
  currentApplicantStatus?: EpJobApplicationEpStatusEnum;
};

export type MixpanelApplicantInvitedTooltipProperties = {
  location: 'tab' | 'list' | 'preview' | 'detail';
};

export type MixpanelScreeningTooltipProperties = {
  content: string;
  position: string;
};

export type MixpanelApplicantRecommendationFeedbackProperties = {
  applicantID: string;
  appliedDate: string; // date iso string
  vacancyCode: string;
  feedbackType: 'like' | 'dislike';
};

export type MixpanelAccountTabProperties = {
  tabTitle: string;
};

export type MixpanelPremiumOfferingProperties = {
  linkPosition:
    | 'sideBar'
    | 'dashboard'
    | 'account'
    | 'FreeTrialEnd'
    | 'limitBasicVacancy'
    | 'limitBasicApplicantView'
    | 'noQuotaPopUp'
    | 'switchQuotaConfirmation'
    | 'emptyStateInterview'
    | 'emptyStateInvitedApplicantPage'
    | 'NoQuotaEditDraft'
    | 'NoQuotaCreate'
    | 'NoQuotaCopy'
    | 'NoQuotaAlert';
};

export type MixpanelPremiumOfferingSelectProperties = {
  packageOption: string;
  linkPosition?: MixpanelPremiumOfferingProperties['linkPosition'];
  contactedPhoneNumber?: string;
};

export type MixpanelVacancyCreateProperties = {
  location:
    | 'dashboard'
    | 'vacancyPage'
    | 'sideBarExpand'
    | 'sideBarCollapse'
    | 'vacancyEmptyPage'
    | 'deletedDraft';
};

export type MixpanelVacancyCreateSubmitProperties = {
  restrictionCity: boolean;
  restrictionEducationLevel: boolean;
  isScreeningQuestion: boolean;
  screeningQuestion: string[];
  jobRole: string;
};

export type MixpanelVacancyUpdateProperties = {
  trackingLocation: string;
  currentVacancyIsPublished?: boolean;
  currentVacancyTitle?: string;
  currentVacancyProvince?: string;
  currentVacancyCity?: string;
  currentVacancyDistrict?: string;
  currentVacancyCloseDate?: string; // date iso string
  newVacancyIsPublished?: boolean;
  newVacancyTitle?: string;
  newVacancyProvince?: string;
  newVacancyCity?: string;
  newVacancyDistrict?: string;
  newVacancyCloseDate?: string; // date iso string
  isHitFreePlanLimit?: boolean | null;
  jobRole: string;
};

export type MixpanelVacancyUpdateStatusProperties = {
  vacancyCode: string;
  currentVacancyStatus: string;
  newVacancyStatus: string;
  isPromoted: boolean;
};

export type MixpanelVacancyHighlightSelectProperties = {
  vacancyHighlightActivateMethod: 'shortcut' | 'dropdown';
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
};

export type MixpanelVacancyHighlightSubmitProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  companyCode: string;
  companyName: string;
  jobTierCategory: string;
};

export type MixpanelVacancyHighlightCancelProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
};

export type MixpanelVacancyUnhighlightSelectProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
};

export type MixpanelVacancyUnhighlightSubmitProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  jobTierCategory: string;
};

export type MixpanelVacancyUnhighlightCancelProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
};

export type MixpanelMenuSidebarSelectProperties = {
  menuSelect:
    | 'dashboard'
    | 'vacancy'
    | 'applicant'
    | 'candidate'
    | 'account'
    | 'term & policy';
};

export type MixpanelVacancySeePreviewProperties = {
  locationPreview: 'create' | 'update' | 'copy' | 'repost';
};

export type MixpanelVacancySeePolicyProperties = {
  locationPolicy: 'create' | 'update';
};

export type MixpanelCandidateVacancySelectProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
};

export type MixpanelCandidateSelectProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  candidateID: string;
  candidateName: string;
  invitationStatus?: string; // make optional to work on v2 and v1
  appliedStatus?: boolean; // make optional to work on v2 and v1
};

export type MixpanelCandidateDetailProperties = {
  invitationStatus?: string;
  appliedStatus?: boolean;
};

export type MixpanelCandidateActionProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  candidateID: string;
  candidateName: string;
  action: 'invite' | 'not interested';
  actionPosition: 'preview' | 'detail' | 'list';
};

export type MixpanelCandidateActionConfirmProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  candidateID: string;
  candidateName: string;
  action: 'invite' | 'not interested';
  actionPosition: 'preview' | 'detail' | 'list';
};

export type MixpanelApplicantAcceptSingleProperties =
  MixpanelApplicantScreeningQuestionProperties & {
    appliedDate: string; // date iso string
    vacancyCode: string;
    vacancyCreatedAt: string; // date iso string
    vacancyCloseDate: string; // date iso string
    isRecommendation: boolean;
    section: MixpanelApplicantAcceptRejectActionLocationKeys;
    source?: SourceDialogAcceptRejectApplicant;
  };

export type MixpanelApplicantAcceptBulkProperties = {
  totalSelectedApplicant: number;
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  isRecommendation: boolean;
  currentApplicantStatus: EpJobApplicationEpStatusEnum;
  newApplicantStatus: EpJobApplicationEpStatusEnum;
};

export type MixpanelApplicantRejectSingleProperties =
  MixpanelApplicantScreeningQuestionProperties & {
    rejectionReason: string[];
    isRecommendation: boolean;
    section: MixpanelApplicantAcceptRejectActionLocationKeys;
    vacancyCode?: string;
    source?: SourceDialogAcceptRejectApplicant;
  };

export type MixpanelApplicantRejectBulkProperties = {
  totalSelectedApplicant: number;
  rejectionReason: string[];
  currentApplicantStatus: EpJobApplicationEpStatusEnum;
  newApplicantStatus: EpJobApplicationEpStatusEnum;
  vacancyCode?: string;
};

export type MixpanelApplicantDetailTabProperties = {
  tab: MixpanelProfileDetailTabKeys;
};

export type MixpanelApplicantDownloadProfileProperties = {
  isRecommendation: boolean;
  currentApplicantStatus?: EpJobApplicationEpStatusEnum;
};

export type MixpanelApplicantDownloadCVProperties = {
  isRecommendation: boolean;
  currentApplicantStatus?: EpJobApplicationEpStatusEnum;
};

export type MixpanelLogoutProperties = {
  logoutType: string;
  logoutLocation?: string | null;
};

export type MixpanelApplicantBulkErrorProcessProperties = {
  processName: 'applicant bulk process';
  errorResponse: string | null;
};

export type MixpanelApplicantClosePopoverProperties = {
  section:
    | 'preview contact'
    | 'detail contact'
    | 'download CV'
    | 'download profile';
};
export type MixpanelApplicantSupportLinkProperties = {
  linkType: MixpanelApplicantSupportLinkType;
  currentApplicantStatus: EpJobApplicationEpStatusEnum;
  vacancyCode: string;
  appliedDate: string; // date iso string
};
export enum MixpanelApplicantSupportLinkType {
  web = 'website',
  instagram = 'instagram',
  facebook = 'facebook',
  tiktok = 'tiktok',
  twitter = 'twitter',
  github = 'github',
}

export type MixpanelVacancyRepostProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyRepostConfirmProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyRepostFirstProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyRepostSecondProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyRepostThirdProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyRepostSubmitProperties = {
  vacancyCode: string;
  jobRole: string;
};

export type MixpanelApplicantScreeningSetProperties = {
  vacancyCode: string;
  vacancyTitle: string;
  vacancyCloseDate: string;
};

export type MixpanelApplicantScreeningSetSubmitProperties = {
  vacancyCode: string;
  vacancyTitle: string;
  vacancyCloseDate: string;
  idealAnswerSkill: {
    [key: string]: string;
  };
  idealAnswerIndustry: {
    [key: string]: string;
  };
  idealAnswerLocation: boolean;
  idealAnswerWorkingExperience: string;
  idealAnswerEducation: boolean;
  idealAnswerDocument: string[];
  idealAnswerPolicy: string;
};

export type MixpanelApplicantScreeningPreviewTabProperties = {
  vacancyCode: string;
  vacancyTitle: string;
  vacancyCloseDate: string;
};

export type MixpanelVacancyCloseSurveyProperties = {
  vacancyCode: string;
  surveyAnswer: string;
};

export enum MixpanelVacancyShareMedia {
  linkedin = 'Linkedin',
  copy_link = 'Copy Link',
}

export enum MixpanelVacancySharelinkPosition {
  after_create_vacancy = 'after create vacancy',
  after_copy_vacancy = 'after copy vacancy',
  after_repost_vacancy = 'after repost vacancy',
  on_list = 'on list',
}

export type MixpanelVacancyShareProperties = {
  media: MixpanelVacancyShareMedia;
  vacancyCode: string;
  vacancyName: string;
  vacancyCreatedAt: string;
  vacancyClosingDate: string;
  linkPosition: MixpanelVacancySharelinkPosition;
};

export type MixpanelAccountFaqProperties = {
  linkPosition: MIXPANEL_ACCOUNT_FAQ_LINK_POSITION;
};

export type MixpanelAccountHelpProperties = {
  linkPosition: MIXPANEL_ACCOUNT_HELP_LINK_POSITION;
};

export type MixpanelApplicantScreeningQuestionProperties = {
  isScreeningQuestion?: boolean;
  isAnswerScreeningQuestionSet?: boolean;
  isFullScoreScreeningQuestion?: boolean | string;
  totalApplicantFullScore?: number;
  screeningAnswerStatus?: MIXPANEL_SCREENING_ANSWER_STATUS;
};

export type MixpanelVacancyCopyProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyCopyConfirmProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyCopyFirstProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyCopySecondProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyCopyPublishProperties = {
  vacancyCode: string;
  position: string;
};

export type MixpanelVacancyCopyValidationProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyCopySubmitProperties = {
  vacancyCode: string;
  newVacancyCode: string;
  jobRole: string;
};

export type MixpanelVacancyNavigationProperties = {
  location: 'Copy';
  fromStep: number;
  toStep: number;
};

export type MixpanelVacancyLimitProperties = {
  vacancyCode: string;
  action: 'create' | 'repost' | 'edit' | 'copy';
};

export type MixpanelApplicantChangeVacancyProperties = {
  vacancyCode: string;
};

export type MixpanelVacancyActionProperties = {
  action: string;
  vacancyTab: VACANCY_TABS_INDONESIA;
};

export type MixpanelVacancyOpenAllApplicantProperties = {
  vacancyName: string;
  jobCode: string;
};

export type MixpanelVacancyOpenSpecificApplicantProperties = {
  applicantStatus: string;
};

export type MixpanelVacancySelectProperties = {
  locationSelect: 'dashboard';
  vacancyCode: string;
};

export type MixpanelVacancyQuotaSwitch = {
  originalJobQuota: string | undefined;
  usingJobQuota: string;
  action: string;
  vacancyCode: string;
};

export type MixpanelVacancyQuotaCheck = {
  action: 'check' | 'detail' | 'upgrade' | 'help';
};

export type MixpanelStickyWarning = {
  position: 'top navbar';
  action: 'check package detail' | 'cancel';
  warningMessage: 'package near expired';
};

export type MixpanelCandidateFilterProperties = {
  educationLevelFilter: string[];
  genderFilter: string;
  ageMinimumFilter: number;
  ageMaximumFilter: number;
  locationFilter: string[];
  salaryMinimumFilter: number;
  salaryMaximumFilter: number;
  yearOfExperienceFilter: string;
  vacancyCode: string;
  universityName: string[];
  invitationStatus: string[];
  experienceIndustryFilter: string[];
  skillFilter: string[];
};

export type MixpanelCandidateResponseProperties = {
  candidateID: string;
  vacancyCode: string;
  candidateName: string;
  invitationStatus: string;
  appliedStatus: boolean;
  section: string;
};

export type MixpanelCandidateTooltipProperties = {
  candidateID: string;
  vacancyCode: string;
  candidateName: string;
  invitationStatus: string;
};

export type MixpanelCandidateInvitationPreviewProperties = {
  vacancyCode: string;
  vacancyCreatedAt: string; // date iso string
  vacancyCloseDate: string; // date iso string
  candidateID: string;
  candidateName: string;
};

export type MixpanelApplicantProceedPopupProperties = {
  applicantNumber: number;
  buttonType: 'close' | 'continue';
  option: InterviewInvitationDialogInvitationTypeKey;
};

export type MixpanelApplicantProceedOnlyChangeStatusPopupProperties = {
  buttonType: 'close' | 'change status' | 'cancel';
  vacancyCode: string;
  vacancyName: string;
  companyCode: string;
  companyName: string;
  applicantNumber: number;
};

export type MixpanelApplicantInterviewFillDetailProperties = {
  buttonType: 'next' | 'close';
  step: string;
  sessionCreationType?: InterviewInvitationFormSessionSelectTypeKey;
  vacancyName: string;
  sessionName: string;
  interviewDate?: string; // ISO date
  interviewType?: EpInterviewSessionType;
  interviewGroup?: EpInterviewSessionSizeType;
  invitationType: InterviewInvitationDialogActionKey;
};

export type MixpanelInterviewDetailFillDetailCancelProperties = {
  buttonType: 'confirm' | 'cancel';
  step: string;
  sessionCreationType?: InterviewInvitationFormSessionSelectTypeKey;
  vacancyName: string;
  sessionName: string;
  invitationType: InterviewInvitationDialogActionKey;
};

export type MixpanelApplicantInterviewFillDetailBackProperties = {
  step: string;
};

export type MixpanelApplicantInterviewDetailSubmitProperties = {
  interviewType?: EpInterviewSessionType;
  interviewGroup?: EpInterviewSessionSizeType;
  sessionName: string;
  vacancyCode: string;
  vacancyName: string;
  companyCode: string;
  companyName: string;
  interviewSessionTimeStart?: string; // ISO date
  interviewSessionTimeEnd?: string; // ISO date
  invitationType: InterviewInvitationDialogActionKey;
  sessionCreationType?: InterviewInvitationFormSessionSelectTypeKey;
  applicantNumber: number;
  additionalNote: InterviewInvitationFormRecruiterMessageStatusKey;
};

export type MixpanelManageInterviewApplicantRescheduleSingleProperties = {
  vacancyName: string;
  sessionName: string;
  interviewDate: string; // ISO date
  interviewType: EpInterviewSessionType;
  interviewGroup: EpInterviewSessionSizeType;
};

export type MixpanelManageInterviewApplicantRescheduleBulkProperties = {
  vacancyName: string;
  sessionName: string;
  interviewDate: string; // ISO date
  interviewType: EpInterviewSessionType;
  applicantNumber: number;
};

export type MixpanelManageInterviewApplicantReschedulePopUpProperties = {
  buttonType: 'close' | 'cancel' | 'continue';
  selectionType: InterviewInvitationDialogSizeKey;
};

export type MixpanelInterviewSessionProperties = {
  vacancyName: string;
  sessionName: string;
};

export type MixpanelInterviewSpecificSessionProperties =
  MixpanelInterviewSessionProperties & {
    responseState: string;
  };

export type MixpanelInterviewApplicantSearchProperties = {
  keyword: string;
};

export type MixpanelInterviewApplicantTabProperties =
  MixpanelInterviewSessionProperties & {
    tabTitle: string;
  };

export type MixpanelInterviewApplicantCardProperties =
  MixpanelInterviewSessionProperties & {
    interviewDate: string;
    interviewType: string;
    interviewGroup: string;
  };

export type MixpanelManageInterviewFilterProperties = {
  interviewDateStart: string;
  interviewDateEnd: string;
  interviewType: string;
};

export type MixpanelVacancyTabProperties = {
  tabTitle: EpJobVacancyState;
};

export type MixpanelVacancyFilterProperties = {
  tabTitle: EpJobVacancyState;
  locationFilter: string;
};

export type MixpanelVacancySearchProperties = {
  tabTitle: EpJobVacancyState;
  keyword: string;
};

export type MixpanelVacancySaveDraftProperties = {
  location: VACANCY_FORM_TYPE;
  step: string;
};

export type MixpanelVacancyDraftEditProperties = {
  draftName: string;
  draftLocation: string | null;
  lastEditDraft: string; // ISO date
  currentQuota: number;
};

export type MixpanelVacancyDraftSubmitProperties = {
  result: 'success' | 'duplicate' | 'failed' | 'already published' | 'no quota';
};

export type MixpanelVacancyDraftConfirmationCancelProperties = {
  location: VACANCY_FORM_TYPE;
  buttonType: 'cancel' | 'close';
};

export type MixpanelVacancyDraftConfirmationSubmitProperties = {
  location: VACANCY_FORM_TYPE;
  draftName: string;
  draftLocation: string | null;
  draftCode: string;
  isDraftSpecSet: boolean;
  isDraftLocationSet: boolean;
  isDraftDescriptionSet: boolean;
  isDraftSkillsSet: boolean;
  isDraftEducationSet: boolean;
  isDraftSalarySet: boolean;
  currentQuota: number;
};

export type MixpanelVacancyExitWithPositionNameProperties = {
  location: VACANCY_FORM_TYPE;
  buttonType: 'close' | 'save draft' | 'exit' | 'fill position name';
};

export type MixpanelVacancyExitWithoutPositionNameProperties =
  MixpanelVacancyExitWithPositionNameProperties;

export type MixpanelVacancyDraftDeleteConfirmProperties = {
  draftName: string;
  draftLocation: string | null;
  currentQuota: number;
};

export type MixpanelVacancyDraftDeleteCancelProperties = {
  buttonType: 'cancel' | 'close';
};

export type MixpanelVacancyDraftAtNoQuotaCaseProperties =
  MixpanelVacancyDraftConfirmationSubmitProperties;
